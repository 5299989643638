import React from "react";

import Scroller from "./Scroller/Scroller";

const Index = () => {
  return (
    <div>
      <Scroller text={"RUSE"} />
    </div>
  );
};

export default Index;
