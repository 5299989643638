import Scroller from "./Index/Scroller/Scroller";

export default function NotFound() {
  return (
    <div>
      {/* 404 */}
      <Scroller text={"404"} />
    </div>
  );
}
